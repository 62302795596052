import React from "react";
import Categories from "../Categories";
import Footer from "../Footer";
import Cookies from "../Cookies";
import Header from "../Header";

function Layout({ children }) {
  return (
    <div className="bg">
      <Cookies />
      <Header />
      <div>
        <main className="content lg:min-h-full pt-0 px-4 lg:px-20 lg:pb-20 mx-auto" style={{ maxWidth: 1100 }}>
          {children}
        </main>
      </div>
      <Footer />
    </div>
  );
}

export default Layout;

import { Link } from "gatsby";
import React from "react";
import CookieConsent from "react-cookie-consent";
import cookieslogo from "../../images/iconbig.png";

function Cookies() {
  return (
    <CookieConsent
      location="bottom"
      buttonText="OK"
      cookieName="websiteCookie"
      style={{
        background: "#fff",
        color: "#000",
        borderTop: "1px solid #d8d8d8",
        position: "fixed",
      }}
      expires={150}
    >
      Hjemmesiden anvender cookies til at huske dine indstillinger og statistik.
      Denne information deles med tredjepart.{" "}
      <Link
        style={{ fontWeight: "300", textDecoration: "underline" }}
        to="/cookies"
      >
        Læs mere
      </Link>
      <a
        className="w-6 inline-block m-0"
        href="https://www.minecookies.org"
        target="_blank"
        rel="noreferrer"
      >
        <img
          width="24"
          height="20"
          style={{ margin: "0 8px -4px" }}
          src={cookieslogo}
          alt="Minecookies.dk"
        />
      </a>
    </CookieConsent>
  );
}

export default Cookies;

import React, { useState } from "react";
import { Link } from "gatsby";
import { BiMenuAltRight } from "react-icons/bi";
import { GrClose } from "react-icons/gr";

function MobileMenu() {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <button
        aria-label="Open menu"
        onClick={() => setOpen(!isOpen)}
        className={`lg:hidden absolute z-30 top-4 right-4 text-black`}
      >
        {isOpen ? <GrClose size={32} /> : <BiMenuAltRight size={40} />}
      </button>
      {isOpen && (
        <nav className="mobilemenu fixed top-0 left-0 w-full h-full bg-white text-black z-20 px-8 py-16">
          <ul>
            <li className="mb-4">
              <Link
                onClick={() => setOpen(false)}
                exact
                activeClassName="active-category"
                to="/"
              >
                Alle produkter
              </Link>
            </li>
            <li className="mb-4">
              <Link
                onClick={() => setOpen(false)}
                exact
                activeClassName="active-category"
                to="/proteintilskud"
              >
                Proteintilskud
              </Link>
            </li>
            <li className="mb-4">
              <Link
                onClick={() => setOpen(false)}
                activeClassName="active-category"
                to="/energitilskud"
              >
                Energitilskud
              </Link>
            </li>
            <li className="mb-4">
              <Link
                onClick={() => setOpen(false)}
                exact
                activeClassName="active-category"
                to="/udstyr"
              >
                Udstyr
              </Link>
            </li>
            <li className="mb-4">
              <Link
                onClick={() => setOpen(false)}
                activeClassName="active-category"
                exact
                to="/andet"
              >
                Andet
              </Link>
            </li>
          </ul>
        </nav>
      )}
    </>
  );
}

export default MobileMenu;

import React, { useLayoutEffect, useRef } from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import heroImg from "../../images/cover.webp";
import MobileMenu from "../MobileMenu";
import Search from "../Search";

function Header() {
  const location = useLocation();
  return (
    <>
      <header
        className={`header relative w-full pt-8 ${
          location.pathname === "/" ? "header-front" : ""
        }`}
      >
        <div
          className="hero-bg absolute -z-1 top-0 left-0 w-full hidden lg:block"
          style={{ backgroundImage: `url(${heroImg})` }}
        />

        <MobileMenu />
        <div className="p-4 lg:p-0 lg:px-20 top-1/4 w-full z-10 text-black text-center">
          <Link to="/">
            <span className="text-4xl font-bold lg:text-5xl text-center">
              Billigform.dk
            </span>
          </Link>
          <h2 className="text-center mt-4">
            Find øvelser og tilbud på træningsudstyr, sportstøj, kosttilskud med
            mere.
          </h2>
          <Search />
          <nav>
            <ul className="hidden font-bold lg:flex justify-center w-full pt-12">
              <li>
                <Link activeClassName="active-category" className="mx-4" to="/">
                  Hjem
                </Link>
              </li>
              <li>
                <Link
                  activeClassName="active-category"
                  className="mx-4"
                  to="/proteintilskud"
                >
                  Proteintilskud
                </Link>
              </li>
              <li>
                <Link
                  activeClassName="active-category"
                  className="mx-4"
                  to="/energitilskud"
                >
                  Energitilskud
                </Link>
              </li>
              <li>
                <Link
                  activeClassName="active-category"
                  className="mx-4"
                  to="/udstyr"
                >
                  Udstyr
                </Link>
              </li>
              <li>
                <Link
                  activeClassName="active-category"
                  className="mx-4"
                  to="/andet"
                >
                  Andet
                </Link>
              </li>
            </ul>
          </nav>
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          className="absolute bottom-0 left-0"
          style={{ zIndex: "-1" }}
        >
          <path
            fill="#fff"
            fill-opacity="1"
            d="M0,224L80,234.7C160,245,320,267,480,272C640,277,800,267,960,266.7C1120,267,1280,277,1360,282.7L1440,288L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          ></path>
        </svg>
      </header>
    </>
  );
}

export default Header;

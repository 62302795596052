import React, { useState, useEffect } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { handleEncoding } from "../../utils/handleEncoding";

function Search() {
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState(null);
  const data = useStaticQuery(graphql`
    {
      allSitePage {
        edges {
          node {
            context {
              productTitle
              productPath
              productLink
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (value.length < 3) {
      setSuggestions(null);
      return;
    }

    const matches = data.allSitePage.edges.filter(
      (e) =>
        e.node.context?.productTitle &&
        e.node.context?.productTitle[0]?.toLowerCase().match(value.toLowerCase())
    );

    setSuggestions(matches);
  }, [value]);

  const handleClick = () => {
    setSuggestions(null);
    setValue("");
  };

  return (
    <div className="border-2 border-gray-300 rounded mx-auto mt-8 w-full md:w-96 relative z-10">
      <input
        className="w-full bg-white h-12 text-black px-4 rounded-t-sm text-sm"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Søg efter udstyr, kosttilskud, træningstøj mm."
      />
      {suggestions && (
        <div
          style={{ maxHeight: 250 }}
          className="absolute w-full bg-white overflow-scroll border-2"
        >
          <ul className="text-left">
            {suggestions.length > 0 ? (
              suggestions.map((e) => (
                <li
                  onClick={() => handleClick()}
                  className="flex justify-between text-black border-t p-2 font-bold"
                >
                  <Link className="text-sm" to={e.node.context.productPath}>
                    {handleEncoding(e.node.context.productTitle[0])}
                  </Link>
                  <a
                    style={{ background: "#5b994d" }}
                    className="ml-2 p-1 text-white rounded-md whitespace-nowrap font-bold"
                    href={e.node.context.productLink[0]}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Se tilbud
                  </a>
                </li>
              ))
            ) : (
              <li className="flex justify-between text-black border-t p-2 font-bold">
                Ingen resultater fundet
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Search;
